import { SET } from './types';

export const buttonColorTypes = {
  PURPLE: 'purple',
  RASPBERRY: 'raspberry',
}

export const themeTypes ={
  NIGHT: 'night',
  LIGHT: 'light'
}

export const initialState = {
  buttonTitle: '',
  title: '',
  subTitle: '',
  theme: themeTypes.NIGHT,
  pageClickMode: false,
  flare: false,
  buttonColor: buttonColorTypes.PURPLE
};

export const remoteConfig = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET:
      return {
        ...state,
        ...action.payload.cfg,
      };
    default:
      return state;
  }
};
