import React, { useEffect, Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { downloadApp } from 'src/store/app/actions';
import Analytics from 'src/modules/analytics';

import Loading from 'src/components/loader/loader';

const Main = lazy(() => import('./main'));
const Boost = lazy(() => import('./boost'));
const Orgasm = lazy(() => import('./orgasm'));


const Screen = () => {
  const { loaded } = useSelector((state) => state.app);
  const { autoSkip, pageClickMode } = useSelector((state) => state.remoteConfig);
  const pathName = window.location.pathname;
  const page = pathName.substr(1) || 'main';

  useEffect(() => {
    if (loaded && autoSkip) {
      setTimeout(() => {
        Analytics.trackEvent(`auto-skip-${page}`, 'action');
        downloadApp();
      }, autoSkip * 1000);
    }
  }, [loaded, autoSkip, page]);

  const handlePageClick = () => {
    if (pageClickMode) {
      Analytics.trackEvent(`page-${page}`, 'click');
      downloadApp();
    }
  };

  return (
    <div onClick={handlePageClick}>
      {!loaded && <Loading />}
      <Suspense fallback={<Loading />}>
        <Router>
          <Routes>
            <Route exact path='/' element={<Main />} />
            <Route path='/boost' element={<Boost />} />
            <Route path='/orgasm' element={<Orgasm />} />
            <Route path='*' element={<Main />} />
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
};

export default Screen;
